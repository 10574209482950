import { Logo } from "@/components/shared/logo"

interface LoginWrapperProps {
	children: React.ReactNode
}

export const LoginWrapper: React.FC<LoginWrapperProps> = ({ children }) => {
	return (
		<div className="flex h-screen grow dark:bg-gradient-dark">
			<div className="flex grow flex-col justify-center bg-gradient-primary p-10 dark:bg-none max-md:hidden">
				<Logo color="white" className="w-full max-w-[380px]" />
				<div className="mt-2 translate-x-[4px] text-xl font-light leading-none text-primary-foreground opacity-70 lg:text-3xl">
					The Next Generation of Fleet Maintenance &amp; <br />
					Shop Management Software
				</div>
			</div>
			<div className="grid w-full grow place-items-center overflow-y-auto bg-gradient-light p-5 dark:bg-none md:max-w-[420px] md:p-10 dark:md:bg-[rgba(255,255,255,.03)]">
				{children}
			</div>
		</div>
	)
}
