import { Logo, LogoSmall } from "@/components/shared/logo"

export const LoginFormLogos = () => {
	return (
		<div className="flex flex-col items-center self-center">
			<LogoSmall color="white" className="hidden w-[100px] dark:block" />
			<LogoSmall color="default" className="w-[100px] dark:hidden" />
			<Logo
				color="white"
				className="mb-5 hidden w-full max-w-[300px] dark:block dark:md:hidden"
			/>
			<Logo
				color="default"
				className="mb-5 w-full max-w-[300px] dark:hidden md:hidden"
			/>
		</div>
	)
}
